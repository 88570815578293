<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('financial.title')"
        :sub-title="$t('financial.subtitle')"
    />

    <div>
      <filter-header
          :content="content"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderItems"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
                v-if="getAllFinancial"
                :per-page="filterData.per_page"
                :items="getAllFinancial"
                :fields="fields"
                :custimized-items="[
                { name: 'id' },
                { name: 'amount' },
                { name: 'transaction_type' },
                { name: 'subscription_type' },
                { name: 'created_at' },
                { name: 'action' },
              ]"
            >
              <template slot="id" slot-scope="{ data }">
                <div class="d-flex">#{{ data.item.tran_id }}</div>
              </template>
              <template slot="amount" slot-scope="{ data }">
                <div class="d-flex">
                  {{ data.item.amount }} {{ $t('common.SR') }}
                </div>
              </template>
              <template slot="subscription_type" slot-scope="{ data }">
                <div class="d-flex">
                  {{ data.item.subscription_type === 2 ? $t('subscription.monthly2') : $t('subscription.annual2') }}
                </div>
              </template>
              <template slot="transaction_type" slot-scope="{ data }">
                <div class="d-flex">
                  <subscription-subscription-icon v-if="data.item.transaction_type==='subscription'"/>
                  <subscription-downgrade-icon v-if="data.item.transaction_type==='downgrade'"/>
                  <subscription-upgrade-icon v-if="data.item.transaction_type==='upgrade'"/>
                  <subscription-renew-icon v-if="data.item.transaction_type==='renew'"/>

                  <span class="pl-1">{{ $t('subscription.' + data.item.transaction_type) }}</span>
                </div>
              </template>
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    {{
                      moment(data.item.created_at).format(
                          ' DD MMMM YYYY, h:mm A'
                      )
                    }}</span
                  >
                </div>
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-14"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1"/>
                  </template>
                  <b-dropdown-item
                      link-class="py-0min-height: 52px;"
                      @click="toDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </w-tables>
            <wameed-no-data
                v-if="getAllFinancial && getAllFinancial.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalFinancial"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalFinancial.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
    WameedBtn,
  },
  data() {
    return {
      filterData: {
        year: '',
        month: '',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
        period: '',
        transaction_type: ''
      },
      content: [
        {
          title: 'transaction_type',
          key: 'transaction_type',
          id: 'id',
          name: 'name',
          translate: true,
          selectedData: [],
          data: [],
        },
        {
          title: 'period',
          key: 'period',
          id: 'id',
          name: 'name',
          translate: true,
          selectedData: [],
          data: [],
        },
        {
          title: 'subscription_title',
          key: 'subscription_title',
          id: 'id',
          name: 'name',
          translate: true,
          selectedData: [],
          data: [],
        },
        {
          title: 'year',
          key: 'year',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
        {
          title: 'month',
          key: 'month',
          id: 'id',
          name: 'name',
          translate: true,
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'id',
          label: this.$i18n.t('financial.order_no'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$i18n.t('financial.name'),
          sortable: true,
        },
        {
          key: 'transaction_type',
          label: this.$i18n.t('financial.transaction_type'),
          sortable: true,
        },
        {
          key: 'subscription_type',
          label: this.$i18n.t('financial.subscription_type'),
          sortable: true,
        },
        {
          key: 'subscription_title',
          label: this.$i18n.t('financial.subscription_title'),
          sortable: true,
        },
        {
          key: 'amount',
          label: this.$i18n.t('financial.amount'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('financial.order_date'),
          sortable: true,
        },
        {key: 'action', label: ''},
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllFinancial: 'getAllFinancial',
      getTotalFinancial: 'getTotalFinancial',
      getFilterData: 'getFilterData',
    }),
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[0].data = newVal.transaction_type ? newVal.transaction_type : [];
        this.content[1].data = newVal.period ? newVal.period : [];
        this.content[2].data = newVal.years ? newVal.years : [];
        this.content[3].data = newVal.months ? newVal.months : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadFilterData('financial');
  },
  methods: {
    ...mapActions({
      loadFinancial: 'loadFinancial',
      loadFilterData: 'loadFilterData',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadFinancial(this.filterData);
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderItems(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    toDetail(id) {
      this.$router.push({
        name: 'financial-operations-detail',
        params: {
          lang: this.$i18n.locale,
          id: id,
        },
      });
    },
  }

};
</script>
